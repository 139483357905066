//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html(function() {
        return "<i class='fa fa-shopping-cart'></i>"+$(this).html();
    });
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");
    $(window).on("load", function() {
        const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - 	$('.footer-container').outerHeight();
        if (newh > 0) {
          $('.main-container').css('min-height', $('.main-container').outerHeight() + newh +'px');
        }
    });
    if (window.matchMedia('(max-width: 959px)').matches) {
        $(".mobile-header .module-search .form-search .button.button-search").on("click", function (e) {
            if (!$(".mobile-header .item-search").hasClass("fly-out")) {
                e.preventDefault();
                $(".mobile-header .item-search").addClass("fly-out");
                $(".mobile-header .module-search .form-search .input-text").show();
                $(".mobile-header .module-search .form-search .input-text").focus();
                $(".mobile-header .module-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
            }
        });
      }
      $(document).on('click', '.mobile-header .module-search .button-close', function (e) {
          e.preventDefault();
          $(".mobile-header .item-search").removeClass("fly-out");
          $('.mobile-header .button-close').remove();
          $(".mobile-header .module-search .form-search .input-text").hide();

      });

});
